
.activity-container { display: flex; align-items: center; color: #999 ;}

.progress-container { display: flex; height: 4px; margin: 5px 0; }

.progress-item { flex: 1; margin-right: 3px; }
.progress-item:last-child { margin-right: 0; }

.progress-item.progress-new { background-color: #eee; }
.progress-item.progress-driving,
.progress-item.progress-driving_paused,
.progress-item.progress-driving_resumed { background-color: #eaa14a; }
.progress-item.progress-arrived,
.progress-item.progress-arrived_paused,
.progress-item.progress-arrived_resumed { background-color: #eaa14a; }
.progress-item.progress-done { background-color: #a0c876; }

.progress-item.active_false { background-color: #A7001F;  }

.item-header {
    display: flex;
    flex: 1;
    justify-content: space-between; 
}

.item-header span {
    font-size: 1.8rem;
}

.item-header span.driver {
    font-size: 1.4rem;
    color: #737373;
}