.login { 
    height: 100%; 
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    background: url("/images/backgrounds.png") no-repeat 50% 50%;
    min-height: 100vh;
    background-size: cover;
}

.login .logo {
    width: 180px;
    min-height: 46px;
    margin-bottom: 35px;
}

.login .form { 
    display: flex; 
    flex-direction: column;

    width: 330px; 
    height: 300px;

    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.16); 
    padding: 32px 16px; 
   
}

.login .form .loading {
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    height: 300px;
    width: 100%; 
}

.login .form form {
    display: flex; 
    flex: 1;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
}

.login .form h2 {
    text-align: center;
}

.login .form input.error {
    border: solid 1px #bc1414;
}

.login .form .error_message { color: #bc1414; }

.login .form button {
    width: 100%;
}