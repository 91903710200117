.map {
    width: 100%;
    height: 100%;
}



.map .infowindow { font-family: Arial, sans-serif; min-width: 150px; padding: 0 0 0 5px; }
.map .infowindow .heading { margin: 0 0 15px 0; font-size: 18px; }
.map .infowindow .name { display: block; margin-bottom: 10px;}
.map .infowindow button { display: block; width: 100%; margin: 15px 0 0 0; padding: 5px 15px; background: transparent; color: #484848; border: 1px solid #ccc; cursor: pointer; outline: none; }
