.search-form {
    padding: 0 20px 20px 20px;
}

.search-form input[type=text] {
    margin: 0 10px 0 0;
}

.search-form button[type=submit] {
    margin: 0;
}

.search-results {
    padding: 0 20px;
}

.item-header {
    display: flex;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 50px 0;
}

.branche {
    background-color: #f5f5f5;
    padding: 10px 20px;
    font-weight: bold;
}