.header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    z-index: 1000;
    background-color: #57004e;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.2);
}

.header .left {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.header .middle {
    display: flex;
    align-items: center;
}

.header .right {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.header .user {
    display: flex;
    color: #ffffff;
    padding: 5px 10px 5px 5px;
    border-radius: 4px;
    cursor: pointer;
}

.header .user:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.header .user .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 5px;
    height: 30px;
    width: 30px;
    border-radius: 4px;
    margin-right: 10px;
}

.header .user .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.header .user .branche_name {
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.header .user .name {
    font-size: 1rem;
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.7);
}

.header .user_menu_background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background-color: rgba(0,0,0,0);
    cursor: default;
}

.header .user_menu {
    position: absolute;
    width: 150px;
    background-color: #ffffff;
    top: 60px;
    right: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.2);
    z-index: 2100;
    overflow: hidden;
}

.header .user_menu button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 1.3rem;
    line-height: 1.2857em;
    width: 100%;
    border: none;

    color: #848484;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
}

.header .user_menu button svg { margin-right: 5px; }

.header button.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0 10px 0 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.7);
    border: none;
    background: transparent;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
}

.header button.icon:hover {
    color: rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 0.2);
}
