.tabs {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.tab-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
  
.tab-list-item {
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    
}

.tab-list-item.active {
    border-bottom: 2px solid #57004e;
    font-weight: bold;;
}

.tab-content {
    height: 100%;
    overflow-x: hidden; overflow-y: auto; scrollbar-color: #CCC #FFF; scrollbar-width: thin; 
}
.tab-content::-webkit-scrollbar { width: 8px; }
.tab-content::-webkit-scrollbar-thumb { background: #CCC; border: 1px solid #FFF; border-radius: 4px; }
.tab-content::-webkit-scrollbar-track { background: #FFF; border-radius: 4px; }
