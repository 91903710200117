.activity {
    display: flex;
    flex: 1;
    min-height: 80px;
    padding-left: 0px;
}

.activity .info {
    display: flex;
    flex:1;
    flex-direction: column;
    padding: 0px 0px 0px 20px;
    margin-bottom: 25px;
}

.activity .name {
    font-weight: bold;
    margin-bottom: 0px;
    font-size: 1.3rem;
    cursor: pointer;
}

.activity .expected_arival {
    color: #737373;
    font-size: 1.1rem;
    margin: 5px 0px;
}

.activity .arival {
    display: flex;
    flex:1;
    justify-content: space-between; 
    align-items:center;
    color: #737373;
    font-size: 1.1rem;
    margin: 5px 0px;
}

.activity .number {
    color: #9c9c9c;
}


.activity .address {
    margin-top: 5px;
    font-size: 1.15rem;
    color: #737373;
}

.activity .line {
    width:33.3%;
    font-size: 1.25rem;
}

.activity .status_container {
    margin-top: 5px;
    margin-bottom: 20px;
}
.activity .status_container .label { display:inline-block; color: #737373; font-size: 1.1rem; width: 200px; }
.activity .status_container .value { font-weight: bold;  font-size: 1.1rem; }

.activity .time_container {
    display: flex;
    margin-top: 5px;
    margin-bottom: 20px;
   
}

.activity .progress {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.activity .progress .dot { 
    width: 16px;
    height: 16px;
    border-style: solid;
    border-width: 2px;
    border-color: #b1b2b2;
    border-radius: 50%;
    background-color: #fff;
}

.activity .progress .dot { position: relative;}
.activity .progress .dot.done { background-color: rgba(160, 200, 118, 0.4); border-color: #a0c876;}
.activity .progress .dot.driving_paused,
.activity .progress .dot.driving_resumed,
.activity .progress .dot.todock_driving,
.activity .progress .dot.driving { background-color: #fff; border-color: #eaa14a; }
.activity .progress .dot.arrived_paused,
.activity .progress .dot.arrived_resumed,
.activity .progress .dot.todock_arrived,
.activity .progress .dot.arrived { background-color: rgba(234, 161, 74, 0.4); border-color: #eaa14a; }

.activity.active_false .progress .dot { background-color: rgba(167, 0, 31, 0.4); border-color: #A7001F; }

.activity .progress .dot .icon { position: absolute; top: -7px; right: -7px; background-color: #fff; width: 13px; height: 13px; border-radius: 13px; }

.activity .progress .line { 
    width: 2px;
    flex: 1;
    border-left: 2px dashed #b1b2b2;
    margin: 3px 0px;
}

.activity .progress .line.done { border-left: 2px solid #a0c876; }
.activity .progress .line.driving_paused,
.activity .progress .line.driving_resumed,
.activity .progress .line.todock_driving,
.activity .progress .line.driving { border-left: 2px dashed #eaa14a; }
.activity .progress .line.arrived_paused,
.activity .progress .line.arrived_resumed,
.activity .progress .line.todock_arrived,
.activity .progress .line.arrived { border-left: 2px solid #a0c876;  }

.activity:last-child .progress .line {
    display: none;
}


.activity .progress .drive_time {
    position: absolute;
    top: 0;
    color: #737373; 
    font-size: 1rem;

    top: 96%;
    right: 25px;

    background-color: #fff;
    border: solid 1px rgba(0, 0, 0, 0.3);
    padding: 3px 6px;
    border-radius: 5px;
    white-space: nowrap;
}

.activity .activity_times {
    display: flex;
    flex:1;
    justify-content: space-between; 
    margin-bottom: 5px;
}

.activity .activity_times .label { display:inline-block; color: #737373; font-size: 1.1rem; }
.activity .activity_times .value { font-weight: bold; font-size: 1.1rem; }
.activity .activity_times .value.expected { color: green; }
.activity .activity_times .value.not_expected { color: red; }

.activity .eta { display: flex; align-items:center; justify-content: flex-end; min-width: 85px; }

.activity .eta span { display: inline-flex; margin-top: 2px; }

.activity .tooltip {
    position: relative;
    display: inline-flex; 
    align-items:center;
    cursor: pointer;
}
  
.activity .tooltip .tooltiptext {
    display: flex; 
    align-items:center;
    justify-content: center;
    position: absolute;
    visibility: hidden;
    width: 120px;
    background-color: #57004e;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.activity .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #57004e transparent transparent transparent;
}
  
.activity .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}