.details {
    display: flex; 
    height: 100%;
    flex-direction: row;
}

.details .left { flex: 0.25; }
.details .right { flex: 0.75; }


.route_info_header {
    display: flex;
    justify-content: space-between;
    border-bottom: #eee solid 1px;
}

.route_info_header .label {
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.route_info {
    padding: 15px 20px;
    color: #737373;
}

.route_info .line {
    display: flex;
    justify-content: space-between;
}

.route_info .line .label { }
.route_info .line .value { font-weight: bold; }

.route_actions { margin-top: 10px; }

.extradata {
    padding: 10px 20px;
    border-top: #eee solid 1px;
}

.extradata .line {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
}

.extradata .line .label { color: #737373;}
.extradata .line .value { font-weight: bold; }

.activities { padding: 20px; }

.map .infowindow .name { font-weight: bold; font-size: 1.5rem; line-height: 1rem;   }
.map .infowindow .second_name { font-size: 1.4rem; line-height: 1rem; }
.map .infowindow .bold { font-weight: bold; }
.map .infowindow .line { line-height: 1.8rem; }