.behaviour {
    padding: 5px 20px 15px 20px;
    color: #737373;
}

.behaviour .line {
    display: flex;
    justify-content: space-between;
}

.behaviour .line .label { }
.behaviour .line .value { font-weight: bold; }
.behaviour .line .title { font-size: 1.9rem; }

.behaviour .show {
    border: none;
    background: none;
    padding: 0;
    color: #737373;
    text-decoration: underline;
    cursor: pointer;
}


.behaviour .hide {
    border: none;
    background: none;
    padding: 0;
    color: #737373;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}